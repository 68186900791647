import React from "react";
import Routes from "./routers/routes";
import "./App.css";
import { Provider } from "mobx-react";
import { stores } from './stores'
function App() {
  return (
    <Provider {...stores}>
      <Routes />
    </Provider>
  );
}

export default App;
