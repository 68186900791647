import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "pages/Main";
import Privacy from "pages/Privacy";
import Delete from "pages/Delete";
class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={() => <Main />} />
          <Route exact path="/privacy" component={() => <Privacy />} />
          <Route exact path="/delete" component={() => <Delete />} />
        </Switch>
      </Router>
    );
  }
}
export default Routes;
