import SliderItem from "components/slider/SliderItem";
import React, { Component } from "react";
import { observable, action, makeObservable } from "mobx";
import { observer } from "mobx-react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
// import Carousel, { arrowsPlugin } from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const data = [
  {
    img: "/img/woman.jpg",
    money: "С этим приложением я заработала",
    money2: "10 000 ₽",
    title: "Меня зовут Мария, и я ",
    titleSpan: "похудела на 14 кг,",
    titleSpanTwo: "заработав ",
    sum: "10 000 ₽",
    text: "При моем росте в 159 см, вес в 70 кг делал из меня «колобка». Случайно наткнулась на это приложение и решила попробовать. Как же я удивилась, когда всего за пару недель смогла сбросить 3 кг. Что самое интересное — результат закрепился. Все что потребовалось — придерживаться рекомендаций по питанию и заниматься спортом.",
    text2: "Потеряв при этом",
    text2P: "14кг(20%)",
  },
  {
    img: "/img/ket.jpg",
    money: "С этим приложением я заработала ",
    money2: "8 000 ₽",
    title: "Меня зовут Катя, и я ",
    titleSpan: "похудела на 15 кг,",
    titleSpanTwo: "заработав ",
    sum: "8 000 ₽",
    text: "Я не особо верила в успешный результат, но все же решила попробовать. Изначально ничего не менялось, но со временем я заметила, что некоторые вещи стали огромными. Муж также увидел перемены, что положительно сказалось на нашей сексуальной жизни.",
    text2: "Потеряв при этом",
    text2P: "15кг(17%)",
  },
  {
    img: "/img/igor.jpg",
    money: "С этим приложением я заработал ",
    money2: "7 500 ₽",
    title: "Меня зовут Игорь, и я ",
    titleSpan: "похудел на 14 кг,",
    titleSpanTwo: "заработав ",
    sum: "7 500 ₽",
    text: "С юного возраста активно набирал вес, не особо заботясь о здоровье. Годами  позже появилась одышка и постоянная усталость. Решил заняться собой. Друзья посоветовали это приложение. За два месяца в играх я не только сбросил 14 кг, но и укрепил мышцы, привел себя в тонус. Сейчас я активно занимаюсь спортом и поддерживаю других пользователей советами.",
    text2: "Потеряв при этом",
    text2P: "14кг(14%)",
  },
];
@observer
class HistoryScreen extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    makeObservable(this);
  }
  // @observable
  // touchStartY: number = 0;

  // @observable
  // avtoplay: boolean = true;

  // @observable
  // touchStartX: number = 0;

  // @observable
  // touchPositionY: number = 0;

  // @observable
  // touchPositionX: number = 0;

  // @observable
  // displayY: number | null = null;

  // @observable
  // displayX: number | null = null;
  // @action
  // startEvent(event: React.TouchEvent<HTMLDivElement>) {
  //   this.setTouchStartY(event.changedTouches[0].pageY);
  //   this.setTouchStartX(event.changedTouches[0].pageX);
  // }

  // moveEvent(event: React.TouchEvent<HTMLDivElement>) {
  //   event.preventDefault();
  // }

  // @action
  // setTouchStartY = (sY: number) => {
  //   this.touchStartY = sY;
  // };

  // @action
  // setTouchStartX = (sX: number) => {
  //   this.touchStartX = sX;
  // };
  // @action
  // endEvent(event: React.TouchEvent<HTMLDivElement>) {
  //   this.touchPositionY = event.changedTouches[0].pageY;
  //   this.touchPositionX = event.changedTouches[0].pageX;
  //   this.displayX = this.touchPositionX - this.touchStartX;
  //   this.displayY = this.touchPositionY - this.touchStartY;

  //   if (Math.abs(this.displayX) > Math.abs(this.displayY)) {
  //     if (this.displayY > 0) {
  //       this.setCurrentIndexNext();
  //     } else if (this.displayY < 0) {
  //       this.setCurrentIndexPrev();
  //     }
  //     event.stopPropagation();
  //   }
  //   this.touchStartY = 0;
  //   this.touchPositionY = 0;
  //   this.touchStartX = 0;
  //   this.touchPositionX = 0;
  // }
  @observable
  currentIndex = 0;

  @action
  updateCurrentSlide = (index: number) => {
    // if (this.currentIndex !== index) {
    this.currentIndex = index;
    // }
  };
  // @action
  // setCurrentIndexPrev = () => {
  //   console.log(this.currentIndex);

  //   this.currentIndex = this.currentIndex - 1;
  // };

  // @action
  // setCurrentIndexNext = () => {
  //   console.log(this.currentIndex);

  //   this.currentIndex = this.currentIndex + 1;
  // };
  SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        className="arrow arrow-right"
        onClick={onClick}
        // onClick={() => this.setCurrentIndexNext()}
      >
        <svg
          width="22"
          height="12"
          viewBox="0 0 22 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7479 5.39168C21.7477 5.39143 21.7475 5.39112 21.7472 5.39087L17.2568 0.922122C16.9204 0.587353 16.3762 0.588599 16.0414 0.925043C15.7066 1.26145 15.7079 1.80556 16.0443 2.14037L19.0591 5.14062L0.859375 5.14062C0.384742 5.14062 0 5.52536 0 5.99999C0 6.47462 0.384742 6.85936 0.859375 6.85936L19.0591 6.85936L16.0443 9.85961C15.7079 10.1944 15.7066 10.7385 16.0414 11.0749C16.3763 11.4114 16.9205 11.4126 17.2568 11.0779L21.7472 6.60911C21.7475 6.60886 21.7477 6.60856 21.748 6.6083C22.0846 6.27237 22.0835 5.72649 21.7479 5.39168Z"
            fill="currentColor"
          />
        </svg>
      </button>
    );
  }
  SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        className="arrow arrow-left"
        onClick={onClick}
        // onClick={() => this.setCurrentIndexPrev()}
      >
        <svg
          width="22"
          height="12"
          viewBox="0 0 22 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.252055 6.60832C0.252312 6.60857 0.252528 6.60887 0.252829 6.60913L4.74323 11.0779C5.07964 11.4126 5.62375 11.4114 5.9586 11.075C6.29342 10.7386 6.29213 10.1944 5.95573 9.85963L2.94087 6.85938L21.1406 6.85938C21.6153 6.85938 22 6.47464 22 6.00001C22 5.52538 21.6153 5.14064 21.1406 5.14064L2.94091 5.14063L5.95569 2.14039C6.29209 1.80558 6.29338 1.26146 5.95856 0.925062C5.62371 0.588573 5.07955 0.587413 4.74319 0.92214L0.252785 5.39088C0.252528 5.39114 0.252312 5.39144 0.252011 5.3917C-0.0845618 5.72763 -0.083488 6.2735 0.252055 6.60832Z"
            fill="currentColor"
          />
        </svg>
      </button>
    );
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <this.SampleNextArrow />,
      prevArrow: <this.SamplePrevArrow />,
    };
    return (
      <div className="historyScreen" id="historyScreen">
        <div className="container-history">
          <h2>
            Истории <span>успеха</span>
          </h2>
          <svg
            className="metr"
            width="81"
            height="25"
            viewBox="0 0 81 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.406127 3.80432L0.406128 18.3877C5.24983 21.558 11.5736 24.2 18.9737 24.2C36.8685 24.1999 41.7122 12.5755 59.0688 12.5755C68.6217 12.5755 78.04 19.2331 81 22.1921L81 8.34842C75.8872 4.22702 68.3526 -5.63758e-05 59.0688 -5.597e-05C39.4249 -5.51113e-05 35.9266 11.5187 18.3009 11.5187C9.28626 11.6244 3.36617 6.86895 0.406127 3.80432Z"
              fill="#00C78B"
            />
            <path
              d="M5.38465 14.9007C4.98101 14.9007 4.71191 15.112 4.71191 15.429L4.71191 20.1845C5.11556 20.3959 5.5192 20.6072 5.92284 20.7129L5.92284 15.3234C6.05739 15.2177 5.78829 14.9007 5.38465 14.9007Z"
              fill="white"
            />
            <path
              d="M11.9786 20.3963C11.575 20.3963 11.3059 20.6076 11.3059 20.9246L11.3059 22.6155C11.7096 22.7211 12.1132 22.8268 12.5168 22.9325L12.5168 20.9246C12.5168 20.6076 12.2477 20.3963 11.9786 20.3963Z"
              fill="white"
            />
            <path
              d="M18.4368 17.8597C18.0331 17.8597 17.764 18.0711 17.764 18.3881L17.764 23.5663C18.1677 23.5663 18.5713 23.5663 18.975 23.5663L19.1095 23.5663L19.1095 18.3881C19.1095 18.0711 18.8404 17.8597 18.4368 17.8597Z"
              fill="white"
            />
            <path
              d="M25.5675 20.8183C25.1639 20.8183 24.8948 21.0297 24.8948 21.3467L24.8948 23.1432C25.2984 23.0375 25.7021 22.9318 26.1057 22.9318L26.1057 21.3467C26.1057 21.0297 25.8366 20.8183 25.5675 20.8183Z"
              fill="white"
            />
            <path
              d="M31.488 15.535C31.0844 15.535 30.8153 15.7463 30.8153 16.0634L30.8153 21.6642C31.2189 21.5586 31.6226 21.3472 32.0262 21.2415L32.0262 16.1691C32.1608 15.7463 31.8917 15.535 31.488 15.535Z"
              fill="white"
            />
            <path
              d="M38.0801 15.6409C37.6764 15.6409 37.4073 15.8523 37.4073 16.1693L37.4073 18.7056C37.811 18.4942 38.2146 18.2829 38.6183 18.0715L38.6183 16.275C38.7528 15.958 38.3492 15.6409 38.0801 15.6409Z"
              fill="white"
            />
            <path
              d="M44.5382 9.40538C44.1346 9.40538 43.8655 9.61673 43.8655 9.93376L43.8655 15.429C44.2691 15.2176 44.6728 15.0062 45.0764 14.9006L45.0764 9.93376C45.211 9.61673 44.9419 9.40538 44.5382 9.40538Z"
              fill="white"
            />
            <path
              d="M51.1316 10.1453C50.728 10.1453 50.4589 10.3567 50.4589 10.6737L50.4589 12.9986C50.8625 12.8929 51.2661 12.7872 51.6698 12.6816L51.6698 10.568C51.8043 10.3567 51.5352 10.1453 51.1316 10.1453Z"
              fill="white"
            />
            <path
              d="M57.725 6.12915C57.3213 6.12915 57.0522 6.34051 57.0522 6.65754L57.0522 12.0471C57.4559 12.0471 57.8595 12.0471 58.2632 12.0471L58.2632 6.76322C58.2632 6.34051 57.9941 6.12915 57.725 6.12915Z"
              fill="white"
            />
            <path
              d="M64.1829 10.1456C63.7792 10.1456 63.5101 10.3569 63.5101 10.674L63.5101 12.3648C63.9138 12.4705 64.3174 12.5761 64.7211 12.6818L64.7211 10.674C64.8556 10.3569 64.5865 10.1456 64.1829 10.1456Z"
              fill="white"
            />
            <path
              d="M71.3135 9.19455C71.3135 8.87752 71.0444 8.66616 70.6408 8.66616C70.2371 8.66616 69.968 8.87752 69.968 9.19455L69.968 14.4784C70.3717 14.6897 70.7753 14.9011 71.1789 15.0068L71.1789 9.19455L71.3135 9.19455Z"
              fill="white"
            />
            <path
              d="M76.2922 15.0065C75.8886 15.0065 75.6195 15.2178 75.6195 15.5349L75.6195 17.4371C76.0231 17.7541 76.4268 17.9654 76.8304 18.2825L76.8304 15.6405C76.965 15.2178 76.6959 15.0065 76.2922 15.0065Z"
              fill="white"
            />
          </svg>
          {/* <div
            className="slider-wrap"
            onTouchStart={(event) => this.startEvent(event)}
            onTouchMove={(event) => this.moveEvent(event)}
            onTouchEnd={(event) => this.endEvent(event)}
          > */}
          <Slider {...settings}>
            {data.map((element, index) => (
              <div
                className="wrap-slider"
                key={index}
                // className={`slider ${
                //   index === this.currentIndex
                //     ? "slidercurrent"
                //     : index < this.currentIndex
                //     ? "sliderbefore"
                //     : "sliderafter"
                // }`}
              >
                <SliderItem
                  img={element.img}
                  money={element.money}
                  money2={element.money2}
                  titleSpan={element.titleSpan}
                  titleSpanTwo={element.titleSpanTwo}
                  title={element.title}
                  sum={element.sum}
                  text={element.text}
                  text2={element.text2}
                  text2P={element.text2P}
                />
              </div>
            ))}
          </Slider>
          {/* </div> */}
          {/* <button
            className="arrow arrow-left"
            onClick={() => this.setCurrentIndexPrev()}
          >
            <svg
              width="22"
              height="12"
              viewBox="0 0 22 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.252055 6.60832C0.252312 6.60857 0.252528 6.60887 0.252829 6.60913L4.74323 11.0779C5.07964 11.4126 5.62375 11.4114 5.9586 11.075C6.29342 10.7386 6.29213 10.1944 5.95573 9.85963L2.94087 6.85938L21.1406 6.85938C21.6153 6.85938 22 6.47464 22 6.00001C22 5.52538 21.6153 5.14064 21.1406 5.14064L2.94091 5.14063L5.95569 2.14039C6.29209 1.80558 6.29338 1.26146 5.95856 0.925062C5.62371 0.588573 5.07955 0.587413 4.74319 0.92214L0.252785 5.39088C0.252528 5.39114 0.252312 5.39144 0.252011 5.3917C-0.0845618 5.72763 -0.083488 6.2735 0.252055 6.60832Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            className="arrow arrow-right"
            onClick={() => this.setCurrentIndexNext()}
          >
            <svg
              width="22"
              height="12"
              viewBox="0 0 22 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7479 5.39168C21.7477 5.39143 21.7475 5.39112 21.7472 5.39087L17.2568 0.922122C16.9204 0.587353 16.3762 0.588599 16.0414 0.925043C15.7066 1.26145 15.7079 1.80556 16.0443 2.14037L19.0591 5.14062L0.859375 5.14062C0.384742 5.14062 0 5.52536 0 5.99999C0 6.47462 0.384742 6.85936 0.859375 6.85936L19.0591 6.85936L16.0443 9.85961C15.7079 10.1944 15.7066 10.7385 16.0414 11.0749C16.3763 11.4114 16.9205 11.4126 17.2568 11.0779L21.7472 6.60911C21.7475 6.60886 21.7477 6.60856 21.748 6.6083C22.0846 6.27237 22.0835 5.72649 21.7479 5.39168Z"
                fill="currentColor"
              />
            </svg>
          </button> */}
          {/* <img
            className="arrow arrow-left"
            src="/img/arrow.svg"
            alt=""
            onClick={() => this.setCurrentIndexPrev()}
          />
          <img
            className="arrow arrow-right"
            src="/img/arrow.svg"
            alt=""
            onClick={() => this.setCurrentIndexNext()}
          /> */}
        </div>
      </div>
    );
  }
}

export default HistoryScreen;
