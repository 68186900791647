import React, { Component } from "react";
import Logo from "components/header/Logo";
import { Link } from "react-router-dom";

class PrivacyScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="privacy" id="privacy">
        <div className="container">
          <div className="navbar">
            <Logo />
          </div>
          <div className="wrap-button-privacy">
            <Link className="button-history" to="/">
              На Главную
            </Link>
          </div>
          <div className="wrap-p">
            <h2 className="wrap-word">
              ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </h2>
            <p>
              {" "}
              Настоящая политика конфиденциальности и обработки персональных
              данных (далее - Политика) составлена в соответствии с требованиями
              Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных»
              и действует в отношении всей информации, которую приложение или
              Веб-сервис (далее - «Сервис»), принадлежащий Борисову Сергею
              Борисовичу (далее – Оператор), может получить о Пользователе во
              время использования Сервиса.
            </p>
            <h2>ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h2>
            <p>
              {" "}
              В настоящей политике конфиденциальности и обработки персональных
              данных используются следующие термины:
            </p>
            <p>
              {" "}
              <b>Сервис</b> (Мобильное приложение, Веб-сервис в сети Интернет) –
              совокупность графических и информационных материалов, а также
              программ для ЭВМ и мобильных устройств, баз данных.
            </p>
            <p>
              <b> Оператор</b> – ИП Борисов Сергей Борисович, являющийся
              правообладателем Сервиса, а также его уполномоченные сотрудники на
              управление Сервиса, которые организуют и (или) осуществляет
              обработку персональных данных, а также определяют цели обработки
              персональных данных, состав персональных данных, подлежащих
              обработке, действия (операции), совершаемые с персональными
              данными.
            </p>
            <p>
              {" "}
              <b>Персональные данные</b> - любая информация, относящаяся к прямо
              или косвенно определенному или определяемому физическому лицу
              (субъекту персональных данных).
            </p>
            <p>
              <b>Обработка персональных данных</b> - любое действие (операция)
              или совокупность действий (операций), совершаемых с использованием
              средств автоматизации или без использования таких средств с
              персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных.
            </p>
            <p>
              <b> Конфиденциальность персональных данных</b> - обязательное для
              соблюдения Оператором или иным получившим доступ к персональным
              данным лицом требование не допускать их распространения без
              согласия субъекта персональных данных или наличия иного законного
              основания.
            </p>
            <p>
              <b> Пользователи</b> - лица, имеющие доступ к Сервису, посредством
              мобильного устройства и использующие его в соответствии с
              Правилами Сервиса. Пользователи являются субъектами персональных
              данных.
            </p>
            <ol>
              <li>
                {" "}
                <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                <ol>
                  <li>
                    1.1. Целью разработки Политики является определение порядка
                    сбора, записи, систематизации, накопления, хранения,
                    уточнения (обновления, изменения), извлечения,
                    использования, передачи (распространения, предоставления,
                    доступа), обезличивания, блокирования, удаления, уничтожения
                    персональных данных, обрабатываемых ИП Борисов Сергей
                    Борисович, ИНН 780212393353, ОРГНИП 321784700115159 (далее –
                    «Оператор»), собираемых с использованием Сервиса, а также
                    всех его сервисов, содержащих ссылку на данную Политику,
                    независимо от способа их использования или доступа, включая
                    доступ с мобильных устройств, включая все субдомены (далее –
                    «Сервис»).
                  </li>
                  <li>
                    1.2. Политикой посетитель Сервиса как субъект персональных
                    данных уведомлен и дает свое согласие о возникающей в
                    процессе работы Сервиса объективной необходимости разрешить
                    доступ к своим персональным данным для программных средств
                    Оператора и третьих лиц (партнеров или поставщиков услуг
                    Оператора). Данный доступ обеспечивается исключительно для
                    целей, определенных Политикой (п. 3.1.).
                  </li>
                  <li>
                    1.3. В случае несогласия субъекта персональных данных
                    полностью либо в части с условиями Политики – использование
                    Сервиса должно быть немедленно прекращено.
                  </li>
                  <li>
                    1.4. Оператор не проверяет достоверность персональных
                    данных, предоставляемых Пользователем и не осуществляет
                    контроль за их дееспособностью, но при этом исходит из того,
                    что Пользователь предоставляет достоверную и достаточную
                    персональную информацию по вопросам, предлагаемым в форме
                    запроса (заявки), и поддерживает эту информацию в актуальном
                    состоянии.
                  </li>
                  <li>
                    1.5. В случае несогласия Пользователем с условиями настоящей
                    Политики конфиденциальности и обработки персональных данных,
                    Пользователь должен прекратить использование Сервиса.
                  </li>
                  <li>
                    1.6. Оператор предпринимает меры предосторожности, включая
                    правовые, организационные, административные, технические и
                    физические для обеспечения защиты персональных данных
                    Пользователей в соответствии со статьей 19 Федерального
                    закона от 27.07.2006 № 152-ФЗ «О персональных данных» в
                    целях обеспечения защиты персональных данных Пользователя от
                    неправомерного или случайного доступа к ним, уничтожения,
                    изменения, блокирования, копирования, распространения, а
                    также от иных неправомерных действий третьих лиц.
                  </li>
                </ol>
              </li>
              <li>
                <h2 className="wrap-word">
                  2. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.
                </h2>
                <h2>СОСТАВ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОБРАБАТЫВАЕМЫХ ОПЕРАТОРОМ</h2>
                <ol>
                  <li>
                    2.1. Оператор обрабатывает данные физических лиц, которые
                    являются пользователями и посетителями Сервиса, которые
                    подписались на e-mail рассылку, рассылку по смс, рассылку в
                    социальные сети через соответствующие формы подписки,
                    заполнения любой формы обратной связи, а также при
                    регистрации пользователя в Сервисе.
                  </li>
                  <li>
                    2.2. Оператор собирает и хранит только те персональные
                    данные, которые необходимы для предоставления полного или
                    частичного функционала Сервиса или исполнения соглашений и
                    договоров с Пользователем, за исключением случаев, когда
                    законодательством предусмотрено обязательное хранение
                    персональных данных в течение определенного законом срока.
                    <p> Перечень обрабатываемых персональных данных:</p>
                    <ol>
                      <li>2.2.1. имя, фамилия, отчество;</li>
                      <li>2.2.2. адрес электронной почты (e-mail);</li>
                      <li>2.2.3. номер мобильного телефона;</li>
                      <li>
                        2.2.4. рост, вест, биометрические параметры (обхват
                        груди, обхват талии, обхват бедер и иные);
                      </li>
                      <li>2.2.5. дата рождения и возраст;</li>
                      <li>
                        2.2.6. дополнительные данные, получаемые при доступе к
                        Сервису, включающие в себя данные о технических
                        средствах (устройствах), технологическом взаимодействии
                        с Сервисом (в т.ч. вид операционной системы
                        пользователя, географическое положение, поставщик услуг
                        Интернета, данные, полученные в результате доступа к
                        камере, микрофону и т.п. устройств), и последующих
                        действиях субъекта персональных данных на Сервисе.
                      </li>
                      <li>
                        2.2.7. информация, полученная в результате действий
                        субъекта персональных данных на Сервисе (в частности,
                        размещение фотографий, принятии участия / отказа от
                        участия в Марафонах).
                      </li>
                      <li>
                        2.3. Оператор не осуществляет намеренно обработку
                        персональных данных несовершеннолетних лиц.
                        Рекомендовано пользоваться Сервисом лицам, достигшим 18
                        лет. Ответственность за действия несовершеннолетних,
                        включая их участие в марафонах, лежит на законных
                        представителях несовершеннолетних. Все посетители,
                        младше 18 лет, обязаны получить разрешение своих
                        законных представителей прежде, чем предоставлять
                        какую-либо персональную информацию о себе. Если
                        Оператору станет известно о том, что он получил
                        персональную информацию о несовершеннолетнем лице без
                        согласия законных представителей, то такая информация
                        будет удалена в возможно короткие сроки.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h2>3. ОБЩИЕ ТРЕБОВАНИЯ ПРИ ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <ol>
                  <li>
                    3.1. Обработка персональных данных на Сервисе осуществляется
                    в следующих целях:
                    <ol>
                      <li>
                        3.1.1. для надлежащего исполнения услуг Оператора и/или
                        иных Пользователей Сервиса;
                      </li>
                      <li>
                        3.1.2. для совершенствования и персонализации Сервиса;
                      </li>
                      <li>
                        3.1.3. для предоставления рекламных и маркетинговых
                        материалов Сервиса, в том числе посредством
                        email-рассылки на email, указанные соответствующим
                        субъектом персональных данных при заполнении контактных
                        форм в Сервисе;
                      </li>
                      <li>
                        3.1.4. для обнаружения, предотвращения, смягчения
                        последствий и расследования мошеннических или незаконных
                        действий в отношении Оператора;
                      </li>
                      <li>
                        3.1.5. для идентификации Пользователя в рамках
                        соглашений и договоров Сервисом, предоставления
                        Пользователю персональных услуг в Сервисе, обработки и
                        получения платежей;
                      </li>
                      <li>
                        3.1.6. для предоставления Пользователю эффективной
                        клиентской и технической поддержки при возникновении
                        проблем, связанных с использованием Сервиса;
                      </li>
                      <li>
                        3.1.7. для улучшения качества работы Сервиса, удобства
                        его использования для Пользователя, разработка новых
                        услуг и сервисов;
                      </li>
                      <li>
                        3.1.8. для проведения статистических и иных исследований
                        на основе обезличенных данных
                      </li>
                    </ol>
                  </li>
                  <li>
                    3.2. Фотоматериалы, а также видеоматериалы, представленные
                    пользователями Сервиса, не используются в целях
                    идентификации Участников и Организаторов марафонов.
                  </li>
                  <li>
                    3.3. Обработке подлежат только те персональные данные,
                    которые отвечают целям их обработки (п.3.1.). Персональные
                    данные не могут быть использованы в целях причинения
                    имущественного и морального вреда субъектам персональных
                    данных.
                  </li>
                  <li>
                    3.4. Порядок получения персональных данных:
                    <ol>
                      <li>
                        3.4.1. Обработка персональных данных на Сервисе
                        осуществляется на срок, необходимый для выполнения
                        целей, для которых, они собирались, любым законным
                        способом, в том числе в информационных системах
                        персональных данных с использованием средств
                        автоматизации или без использования таких средств
                        (смешанная обработка) посредством сети Интернет.
                      </li>
                      <li>
                        3.4.2. Все персональные данные предоставляются
                        (собираются) непосредственно от субъекта персональных
                        данных. Субъект самостоятельно принимает решение о
                        предоставлении своих персональных данных и дает согласие
                        на их обработку Сервисом свободно, своей волей и в своем
                        интересе.
                      </li>
                      <li>
                        3.4.3. Согласие, указанное в п. 3.4.2 Политики, также
                        означает согласие субъекта на передачу третьим лицам, на
                        поручение обработки своих персональных данных третьими
                        лицами, согласие субъекта на трансграничную передачу
                        данных посредством сети Интернет (когда такая передача
                        необходима для эффективного использования Сервиса либо
                        необходима для достижения иных целей, установленных
                        Политикой), а также на получение email, sms-рассылки для
                        получения рекламных и маркетинговых материалов Сервиса.
                        Необходимый уровень защиты персональных данных
                        обеспечивается Оператором.
                      </li>
                      <li>
                        3.4.4. Принимая условия настоящего Соглашения,
                        Пользователь подтверждает свое согласие на обработку
                        Оператором его данных, предоставленных при регистрации в
                        Сервисе, в целях исполнения настоящего Соглашения и
                        разрешения претензий, связанных с исполнением настоящего
                        Соглашения.
                      </li>
                      <li>
                        3.4.5. Для удобства использования Сервиса персональные
                        данные могут быть получены в автоматическом режиме с
                        использованием специального программного обеспечения, в
                        том числе от третьих лиц (например, социальных сетей) с
                        уведомлением субъекта персональных данных перед
                        отправкой запроса на их получение таким образом и для
                        каких целей.
                      </li>
                      <li>
                        3.4.6. Согласие на обработку персональных данных может
                        быть отозвано субъектом персональных данных в любое
                        время путем обращения в службу поддержки Сервиса по
                        адресу mail@slimgame.ru. При этом в случае отзыва
                        согласия на обработку персональных данных: Сервис не
                        дает гарантию корректной работы и доступа ко всем
                        сервисам, в случае такого обращения. Также субъект
                        персональных данных понимает, что удаленные данные могут
                        храниться в системах третьих лиц: в кэш-памяти,
                        поисковых системах, взаимосвязанных прокси-серверах и т.
                        п.
                      </li>
                    </ol>
                  </li>
                  <li>
                    3.5. Права и обязанности сторон при обработке персональных
                    данных:
                    <ol>
                      <li>
                        3.5.1. Субъекты персональных данных обязаны
                        предоставлять Сервису только достоверные персональные
                        данные и своевременно сообщать об изменении своих
                        персональных данных. При этом Оператор не проверяет
                        достоверность персональных данных, и не осуществляет
                        контроль за дееспособностью субъектов персональных
                        данных, и исходит из того, что субъект предоставляет
                        достоверную и достаточную персональную информацию по
                        вопросам, предлагаемым в форме регистрации (обратной
                        связи, подписки), и поддерживает эту информацию в
                        актуальном состоянии. Риск предоставления недостоверных
                        персональных данных при этом несет сам субъект
                        персональных данных.
                      </li>
                      <li>
                        3.5.2. Каждый субъект персональных данных имеет право:
                        <ol>
                          <li>
                            3.5.2.1. на получение полной информации о своих
                            персональных данных и на свободный бесплатный доступ
                            к своим персональным данным, за исключением случаев,
                            предусмотренных действующим законодательством;
                          </li>
                          <li>
                            3.5.2.2. на получение информации, касающейся
                            обработки его персональных данных;
                          </li>
                          <li>
                            3.5.2.3. требовать от Оператора уточнения своих
                            персональных данных, их блокирования или уничтожения
                            в случае, если персональные данные являются
                            неполными, устаревшими, неточными, незаконно
                            полученными или не являются необходимыми для
                            заявленной цели обработки, а также принимать
                            предусмотренные законом меры по защите своих прав;
                          </li>
                          <li>
                            3.5.2.4. иные права, предусмотренные
                            законодательством РФ.
                          </li>
                        </ol>
                        <li>
                          3.5.3. Субъект персональных данных вправе внести
                          необходимые изменения в персональные данные, указанные
                          при регистрации на Сервисе, путем направления
                          соответствующего заявления email сообщением, по адресу
                          mail@slimgame.ru, в службу поддержки Сервиса.
                        </li>
                        <li>
                          3.5.4. Проект обязан безвозмездно предоставить
                          субъекту персональных данных возможность ознакомления
                          с персональными данными, относящимися к этому
                          субъекту, а также внести в них необходимые изменения
                          при предоставлении субъектом персональных данных
                          сведений, подтверждающих, что персональные данные
                          являются неполными, устаревшими, неточными или
                          незаконно полученными. О внесенных изменениях и
                          предпринятых мерах Оператор обязан уведомить субъекта
                          или его представителя и принять разумные меры для
                          уведомления третьих лиц, которым персональные данные
                          этого субъекта были переданы.
                        </li>
                        <li>
                          {" "}
                          3.5.5. Рассмотрение запроса субъекта по поводу его
                          персональных данных осуществляется Оператором в
                          течение 30 (тридцати) календарных дней с момента
                          такого обращения, если иной срок не установлен
                          Политикой. Запрос субъекта персональных данных по
                          вопросам обработки его данных принимается в свободной
                          форме. Для отправки такого запроса субъекту
                          персональных данных необходимо заполнить заявление,
                          распечатать его, поставить свою личную подпись,
                          отсканировать и отправить документ на электронную
                          почту mail@slimgame.ru в формате PDF или JPEG. При
                          этом вся переписка по таким запросам осуществляется
                          через службу поддержки Сервиса путем отправки
                          сообщений на email субъекта персональных данных,
                          указанный при обращении.
                        </li>
                      </li>
                    </ol>
                  </li>
                  <li>
                    3.6. Порядок передачи персональных данных:
                    <ol>
                      <li>
                        3.6.1. В целях эффективной обработки персональных данных
                        Сервис вправе поручить обработку персональных данных
                        другим юридическим или физическим лицам на основании
                        договора (далее - поручение Сервиса), в том числе путем
                        трансграничной передачи данных посредством сети
                        Интернет. При этом отдельного согласия субъекта
                        персональных данных на такую передачу не требуется.
                        Лицо, осуществляющее обработку персональных данных по
                        поручению Сервиса, обязано соблюдать принципы и правила
                        обработки персональных данных, предусмотренные
                        законодательством РФ о персональных данных и несет
                        ответственность за нарушение конфиденциальности таких
                        данных, произошедшее по его вине.
                      </li>
                      <li>
                        3.6.2. Передача персональных данных субъектов, с
                        которыми взаимодействует Сервис, осуществляется только
                        для надлежащего исполнения обязательств, в рамках
                        которых Сервис и указанные субъекты взаимодействуют.
                      </li>
                      <li>
                        3.6.3. При передаче персональных данных субъекта
                        Оператор обязан предупреждать лиц, получающих
                        персональные данные субъектов, о том, что эти данные
                        могут быть использованы лишь в целях, для которых они
                        сообщены, и требовать от этих лиц обеспечения
                        конфиденциальности полученных персональных данных.
                      </li>
                    </ol>
                  </li>
                  <li>
                    3.7. Порядок хранения персональных данных:
                    <ol>
                      <li>
                        3.7.1. Хранение персональных данных осуществляется в
                        электронной форме в соответствующих информационных
                        системах персональных данных, размещаемых в базах данных
                        на территории РФ.
                      </li>
                      <li>
                        3.7.2. Хранение персональных данных осуществляется в
                        форме, позволяющей определить субъекта персональных
                        данных в сроки, обеспечивающим соблюдение и достижение
                        целей обработки персональных данных, установленные
                        Политикой.
                      </li>
                      <li>
                        3.7.3. Хранение персональных данных осуществляется с
                        ограничением доступа, в том числе путем создания
                        соответствующих уровней доступа.
                      </li>
                      <li>
                        3.7.4. Персональные данные, содержащиеся в разных
                        электронных базах, и обработка которых осуществляется
                        для различных целей, хранятся раздельно.
                      </li>
                    </ol>
                  </li>
                  <li>
                    3.8. Порядок прекращения обработки и уничтожение
                    персональных данных:
                    <ol>
                      <li>
                        3.8.1. В случае выявления неточных персональных данных
                        при обращении субъекта персональных данных Сервис обязан
                        осуществить блокирование персональных данных,
                        относящихся к этому субъекту персональных данных, с
                        момента такого обращения на период проверки, если
                        блокирование персональных данных не нарушает права и
                        законные интересы субъекта персональных данных или
                        третьих лиц.
                      </li>
                      <li>
                        3.8.2. В случае подтверждения факта неточности
                        персональных данных Оператор на основании сведений,
                        представленных субъектом персональных данных, обязан
                        уточнить персональные данные в течение 7 (семи) рабочих
                        дней со дня представления таких сведений и снять
                        блокирование персональных данных.
                      </li>
                      <li>
                        3.8.3. В случае выявления неправомерной обработки
                        персональных данных, осуществляемой Сервисом, последний
                        в срок, не превышающий 3 (трех) рабочих дней со дня
                        этого выявления, обязан прекратить неправомерную
                        обработку персональных данных. В случае если обеспечить
                        правомерность обработки персональных данных невозможно,
                        Сервис в срок, не превышающий 10 (десяти) рабочих дней
                        со дня выявления неправомерной обработки персональных
                        данных, обязан уничтожить такие персональные данные. Об
                        устранении допущенных нарушений или об уничтожении
                        персональных данных Сервис обязан уведомить субъекта
                        персональных данных.
                      </li>
                      <li>
                        3.8.4. В случае отзыва субъектом персональных данных
                        согласия на их обработку Сервис обязан прекратить их
                        обработку и в случае, если сохранение персональных
                        данных более не требуется для целей обработки
                        персональных данных, уничтожить персональные данные в
                        срок, не превышающий 30 (тридцати) рабочих дней со дня
                        поступления указанного отзыва.
                      </li>
                      <li>
                        3.8.5. Сервис вправе продолжить использовать
                        персональные данные о субъекте по итогу рассмотрения
                        отзыва согласия на их обработку, обеспечив обезличивание
                        такой информации.
                      </li>
                      <li>
                        3.8.6. Оператор направляет уведомление о результатах
                        рассмотрения запросов субъектов персональных данных,
                        указанные в настоящем разделе, через службу поддержки,
                        по адресу mail@slimgame.ru, путем отправки сообщений на
                        email субъекта персональных данных, указанный в запросе.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h2>4. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <li>
                  4.1. Сервис при обработке персональных данных принимает
                  необходимые правовые, организационные и технические меры от
                  неправомерного или случайного доступа к ним, уничтожения,
                  изменения, блокирования, копирования, предоставления,
                  распространения персональных данных, а также от иных
                  неправомерных действий в отношении персональных данных.
                </li>
                <li>
                  4.2. Обеспечение безопасности персональных данных достигается,
                  в частности:
                  <ol>
                    <li>
                      4.2.1. оценкой эффективности мер по обеспечению
                      безопасности персональных данных до начала использования
                      таких мер;
                    </li>
                    <li>
                      4.2.2. обнаружением фактов несанкционированного доступа к
                      персональным данным и принятием мер по их устранению и
                      недопущению повтора;
                    </li>
                    <li>
                      4.2.3. восстановлением персональных данных,
                      модифицированных или уничтоженных вследствие
                      несанкционированного доступа к ним;
                    </li>
                    <li>
                      4.2.4. установлением правил доступа к персональным данным,
                      обрабатываемым в информационной системе персональных
                      данных, а также обеспечением регистрации и учета всех
                      действий, совершаемых с персональными данными в
                      информационной системе персональных данных;
                    </li>
                    <li>
                      4.2.5. контролем за принимаемыми мерами по обеспечению
                      безопасности персональных данных и уровня защищенности
                      информационных систем персональных данных.
                    </li>
                  </ol>
                </li>
                <li>
                  4.3. Третьи лица, получившие доступ к персональным данным по
                  поручению Оператора, обязуются принимать необходимые
                  организационные и технические меры к обеспечению
                  конфиденциальности такой информации на своем персональном
                  устройстве, с которого осуществляет обработку персональных
                  данных.
                </li>
              </li>
              <li>
                <h2>5. ОТВЕТСТВЕННОСТЬ</h2>
                <ol>
                  <li>
                    5.1. Третьи лица, получившие доступ к персональным данным
                    субъектов персональных данных Сервиса и виновные в нарушении
                    их конфиденциальности, несут ответственность в порядке,
                    установленном законодательством РФ, в том числе согласно
                    заключенным с Оператором договорам, по которым такой доступ
                    был предоставлен
                  </li>
                  <li>
                    5.2. Оператор не несет ответственности за возможное
                    нецелевое использование персональных данных и причинение
                    какого-либо ущерба субъекту персональных данных,
                    произошедшее вследствие: технических неполадок в программном
                    обеспечении и в технических средствах и сетях, находящихся
                    вне контроля Оператора; в связи с намеренным или
                    ненамеренным использованием Сервиса Оператора не по их
                    прямому назначению третьими лицами; необеспечения
                    конфиденциальности паролей доступа или намеренной передачи
                    паролей доступа, иной информации с Сервиса самим субъектом
                    персональных данных при получении услуг Оператора
                    (использовании Сервиса) другим лицам, не имеющим доступа к
                    данной информации; неправомерных действий третьих лиц по
                    доступу к данным Сервиса, в т.ч. персональным данным.
                  </li>
                  <li>
                    5.3. Оператор не несет ответственности за обработку
                    персональных данных третьих лиц, которые посетитель Сервиса
                    Оператора сообщил как свои собственные. Риск привлечения к
                    ответственности в этом случае несет посетитель Сервиса
                    Оператора, предоставивший недостоверные данные.
                  </li>
                  <li>
                    5.4. Оператор не контролирует и не несет ответственность за
                    обработку информации сайтами третьих лиц, на которые субъект
                    персональных данных может перейти по ссылкам, доступным на
                    Сервисе Оператора.
                  </li>
                </ol>
              </li>
              <li>
                <h2>6. РАЗРЕШЕНИЕ СПОРОВ</h2>
                <ol>
                  <li>
                    6.1. До обращения в суд с иском по спорам, возникающим из
                    отношений между субъектом персональных данных и Оператором,
                    обязательным является предъявление претензии (письменного
                    предложения о добровольном урегулировании спора).
                  </li>
                  <li>
                    6.2 Получатель претензии в течение 30 (тридцати) календарных
                    дней со дня получения претензии, письменно уведомляет
                    заявителя претензии о результатах рассмотрения претензии.
                  </li>
                  <li>
                    6.3. При недостижении соглашения спор может быть передан на
                    рассмотрение в судебный орган по месту регистрации Оператора
                    в соответствии с действующим законодательством РФ.
                  </li>
                  <li>
                    6.4. К Политике конфиденциальности и отношениям между
                    субъектом персональных данных и Оператором применяется
                    действующее законодательство РФ.
                  </li>
                </ol>
              </li>
              <li>
                <h2>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
                <ol>
                  <li>
                    7.1. Оператор вправе вносить изменения в Политику без
                    согласия субъектов персональных данных.
                  </li>
                  <li>
                    7.2. Новая Политика вступает в силу с момента ее размещения
                    в Сервисе, если иное не предусмотрено новой редакцией
                    Политики.
                  </li>
                  <li>
                    7.3. Предложения и замечания для внесения изменений в
                    Политику следует направлять по адресу mail@slimgame.ru через
                    службу поддержки Сервиса.
                  </li>
                  <li>
                    7.4. Недействительность отдельных норм Политики, если
                    таковое будет признано решением суда или иного
                    уполномоченного государственного органа, не влечет ее
                    недействительности в целом.
                  </li>
                  <li>
                    7.5. При использовании, обработке персональных данных
                    Оператор не осуществляет специально проверку наличия особого
                    режима обработки персональных данных, установленного
                    законодательством стран, к юрисдикции которого относятся
                    отдельные получатели услуг Оператора или лица,
                    предоставившие свои данные по форме подписки на Сервисе.
                    Если субъект персональных данных является резидентом
                    государства с особым режимом защиты персональных данных,
                    например, в Европейской экономической зоне (EEA), и получает
                    доступ к Сервису из стран Европы, Оператор предпринимает все
                    разумные меры для обеспечения соблюдения таких требований
                    законодательства о защите персональных данных, установленных
                    соответствующим государством. Для этого субъект персональных
                    данных обязан уведомить Оператора о наличии особого режима
                    защиты его персональных данных путем обращения в службу
                    поддержки по адресу mail@slimgame.ru.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyScreen;
