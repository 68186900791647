import AppButton from "components/header/AppButton";
import Header from "components/header/Header";
import React, { Component } from "react";

class FirstScreen extends Component {
  render() {
    return (
      <div className="firstScreen" id="firstScreen">
        <Header />
        <div className="container">
          <div className="wrap-flex">
            <div>
              <h1>
                Худейте{" "}
                <svg
                  width="40"
                  height="47"
                  viewBox="0 0 40 47"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.5714 10.2857C22.4649 10.2857 24 7.98318 24 5.14286C24 2.30254 22.4649 0 20.5714 0C18.6778 0 17.1428 2.30254 17.1428 5.14286C17.1428 7.98318 18.6778 10.2857 20.5714 10.2857Z"
                    fill="#FFAE35"
                  />
                  <path
                    d="M24.9042 26.6503C24.9042 13.2379 36.1212 9.70829 39.8894 3.35503C40.3276 2.64911 39.3636 1.85493 38.7502 2.56085C34.2809 7.85523 27.7085 12.4437 20.2597 12.4437C20.1721 12.4437 20.0845 12.4437 19.9968 12.4437C19.9092 12.4437 19.8216 12.4437 19.7339 12.4437C12.2852 12.4437 5.71275 7.76699 1.24349 2.56085C0.717697 1.85493 -0.333892 2.64911 0.10427 3.35503C3.9601 9.62005 15.0894 13.1496 15.0894 26.6503C15.0894 31.9447 11.146 35.2978 11.7594 46.8572C11.847 46.8572 11.9346 46.8572 12.0223 46.8572H18.4194C18.6823 42.4452 19.1205 39.4451 19.9968 39.4451C21.0484 39.4451 21.4866 42.4452 21.6618 46.8572H27.8837H27.9714C28.8477 34.9448 24.9042 32.0329 24.9042 26.6503Z"
                    fill="#FFAE35"
                  />
                  <path
                    d="M9.61335 34.2858C9.70579 34.2858 9.89067 34.195 9.98311 34.1043C10.0755 34.0136 12.5714 30.8389 12.5714 26.3036C12.5714 22.0403 10.4453 18.6842 10.3529 18.5028C10.168 18.3214 9.89067 18.2306 9.70579 18.3213C9.52091 18.4121 9.42847 18.7749 9.52091 18.9563C9.52091 18.9563 11.647 22.2218 11.647 26.3036C11.647 30.5668 9.2436 33.5601 9.2436 33.5601C9.05872 33.7415 9.15116 34.0136 9.33604 34.1951C9.42847 34.1951 9.52091 34.2858 9.61335 34.2858Z"
                    fill="#FFAE35"
                  />
                  <path
                    d="M7.50786 30.8572C7.6147 30.8572 7.82839 30.7734 7.93523 30.6896C8.04207 30.6058 10.2857 28.4272 10.2857 25.1593C10.2857 22.1428 8.3626 19.7128 8.25575 19.6291C8.04207 19.4615 7.72155 19.3777 7.50786 19.4615C7.29418 19.5453 7.18734 19.8804 7.29418 20.048C7.29418 20.048 9.11049 22.3104 9.11049 25.0755C9.11049 28.0082 7.0805 30.103 6.97365 30.103C6.75997 30.2706 6.86681 30.522 7.08049 30.6896C7.18734 30.8572 7.40102 30.8572 7.50786 30.8572Z"
                    fill="#FFAE35"
                  />
                  <path
                    d="M31.5295 17.1428C31.437 17.1428 31.2521 17.2465 31.1597 17.3502C31.0673 17.4538 28.5714 21.0821 28.5714 26.2654C28.5714 31.1376 30.6975 34.9732 30.7899 35.1806C30.9748 35.3879 31.2521 35.4915 31.437 35.3879C31.6219 35.2842 31.7143 34.8696 31.6219 34.6622C31.6219 34.6622 29.4958 30.9303 29.4958 26.2654C29.4958 21.3931 31.8992 17.9722 31.8992 17.9722C32.0841 17.7648 31.9916 17.4538 31.8068 17.2465C31.8068 17.1428 31.7143 17.1428 31.5295 17.1428Z"
                    fill="#FFAE35"
                  />
                  <path
                    d="M33.8519 18.2858C33.7807 18.2858 33.6382 18.3779 33.567 18.4701C33.4958 18.5623 32 20.9587 32 24.5534C32 27.8716 33.2821 30.5445 33.3533 30.6367C33.4958 30.821 33.7095 30.9132 33.8519 30.821C33.9944 30.7289 34.0656 30.3602 33.9944 30.1759C33.9944 30.1759 32.7835 27.6872 32.7835 24.6456C32.7835 21.4196 34.1368 19.1153 34.2081 19.1153C34.3505 18.931 34.2793 18.6545 34.1368 18.4701C33.9944 18.2858 33.9232 18.2858 33.8519 18.2858Z"
                    fill="#FFAE35"
                  />
                </svg>
                <br />{" "}
                <span>
                  и зарабатывайте{" "}
                  <svg
                    className="purse"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.58125 8.1104L25.926 3.75779L25.0604 2.05089C24.4941 0.941397 23.1129 0.483227 21.9756 1.03573L7.34363 8.1104H9.58125Z"
                      fill="#FFAE35"
                    />
                    <path
                      d="M30.9998 3.88354C30.7973 3.88354 30.5947 3.9105 30.3921 3.9644L26.5523 4.98854L14.8254 8.11038H28.1361H33.9373L33.219 5.54104C32.9428 4.53936 32.0128 3.88354 30.9998 3.88354Z"
                      fill="#FFAE35"
                    />
                    <path
                      d="M36.336 9.6825H35.8111H35.0975H34.3838H28.9371H8.92285H6.29848H4.08849H3.67872H2.30668C1.57923 9.6825 0.930039 10.0104 0.506457 10.527C0.313083 10.765 0.16575 11.039 0.0828748 11.34C0.0322291 11.5286 0 11.7263 0 11.9284V12.1979V14.7583V36.9526C0 38.1924 1.03133 39.1985 2.30208 39.1985H36.3314C37.6022 39.1985 38.6335 38.1924 38.6335 36.9526V30.6864H24.9729C22.8136 30.6864 21.0594 28.975 21.0594 26.8683V24.8111V24.1148V23.4186V21.8734C21.0594 20.8403 21.4829 19.9015 22.169 19.2143C22.7768 18.6033 23.5917 18.1901 24.5033 18.0868C24.6553 18.0688 24.8118 18.0598 24.9683 18.0598H36.7181H37.4318H38.1454H38.6335V11.9284C38.6381 10.6887 37.6067 9.6825 36.336 9.6825Z"
                      fill="#FFAE35"
                    />
                    <path
                      d="M40.2495 20.207C40.0193 20.0004 39.7476 19.8432 39.4437 19.7399C39.2089 19.6636 38.9603 19.6187 38.6979 19.6187H38.638H38.592H37.8783H35.3046H24.9729C23.7021 19.6187 22.6708 20.6248 22.6708 21.8646V22.983V23.6792V24.3755V26.864C22.6708 28.1037 23.7021 29.1099 24.9729 29.1099H38.638H38.6979C38.9603 29.1099 39.2089 29.065 39.4437 28.9886C39.7476 28.8898 40.0193 28.7281 40.2495 28.5215C40.7099 28.1127 40.9999 27.5198 40.9999 26.864V21.8646C40.9999 21.2087 40.7099 20.6158 40.2495 20.207ZM29.7796 24.8112C29.7796 25.4311 29.2639 25.9342 28.6286 25.9342H27.8643C27.2289 25.9342 26.7132 25.4311 26.7132 24.8112V24.0655C26.7132 23.7062 26.8836 23.3872 27.1552 23.1851C27.3532 23.0369 27.5973 22.9426 27.8643 22.9426H28.0576H28.6286C29.2639 22.9426 29.7796 23.4456 29.7796 24.0655V24.8112Z"
                      fill="#FFAE35"
                    />
                  </svg>
                </span>
              </h1>
              <div className="text-header-bold" id="button-app">Мы вас замотивируем</div>
              <div className="text-header">
                Все просто – вы получите деньги за потерянные
                <br className="text-br" /> килограммы.
              </div>
              <div className="wrap-img-first">
                {" "}
                <img className="img" src="/img/phone.png" alt="" />
              </div>

              <AppButton />
            </div>
          </div>
          {/* <a className="nav-link scroll" href="#workScreen">
            <img className="arrow arrow-first" src="/img/arrow.svg" alt="" />
          </a> */}
        </div>
      </div>
    );
  }
}

export default FirstScreen;
