import React, { Component } from "react";
import ContactScreen from "screens/ContactScreen";
import CountScreen from "screens/CountScreen";
import FirstScreen from "screens/FirstScreen";
import Footer from "screens/Footer";
import HistoryScreen from "screens/HistoryScreen";
import MotivationScreen from "screens/MotivationScreen";
import PlayScreen from "screens/PlayScreen";
import WorkScreen from "screens/WorkScreen";

class Main extends Component {
  render() {
    return (
      <>
        <FirstScreen />
        <WorkScreen />
        <PlayScreen />
        <MotivationScreen />
        <HistoryScreen />
        <CountScreen />
        <ContactScreen />
        <Footer />
      </>
    );
  }
}

export default Main;
