import React, { Component } from "react";
import Check from "./Check";

export interface Props {
  img: string;
  money: string;
  money2: string;
  title: string;
  titleSpan: string;
  sum: string;
  titleSpanTwo: string;
  text: string;
  text2: string;
  text2P: string;
}
class SliderItem extends Component<Props> {
  render() {
    const {
      img,
      money,
      money2,
      title,
      titleSpan,
      sum,
      titleSpanTwo,
      text,
      text2,
      text2P,
    } = this.props;
    return (
      <div className="wrap-history">
        <div className="card-money">
          <img className="img-history money" src="/img/money.png" alt="" />
          {/* <img
            className="img-history money-desk"
            src="/img/money-desk.png"
            alt=""
          /> */}

          <div>
            {money} <p>{money2}</p>
          </div>
        </div>
        <div className="wrap-img-history">
          <img className="img-history-woman" src={img} alt="" />
        </div>
        <div className="card-text">
          <img className="img-history" src="/img/text.png" alt="" />
          <div>
            {text2} <p>{text2P}</p>
          </div>
        </div>
        <div className="card-history">
          <h3>
            {title} <span>{titleSpan}</span> при этом{" "}
            <span>{titleSpanTwo}</span>
            <span className="white-space">{sum}</span>
          </h3>
          <p>{text}</p>
          <div className="wrap-check-list">
            <ul className="check-list">
              <div className="history-check">
                <li>
                  <div className="wrap-check">
                    <Check />
                  </div>
                  <span>Мотивация</span>
                </li>
                <li>
                  <div className="wrap-check">
                    <Check />
                  </div>
                  <span>Несложная программа</span>
                </li>
              </div>
              <div className="history-check">
                <li>
                  <div className="wrap-check">
                    <Check />
                  </div>
                  <span>Обучающие ролики</span>
                </li>
                <li>
                  <div className="wrap-check">
                    <Check />
                  </div>
                  <span>Консультация специалиста</span>
                </li>
              </div>
            </ul>
          </div>
          <div className="button-wrap-history">
            <a
              className="button-history"
              // rel="noreferrer"
              // target="_blank"
              href="#button-app"
            >
              Хочу также!
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SliderItem;
