import React, { Component } from "react";

class WorkScreen extends Component {
  render() {
    return (
      <div className="workScreen" id="workScreen">
        <div className="container">
          <h2>
            Как это <span>работает</span>
          </h2>

          <svg
            className="metr"
            width="81"
            height="25"
            viewBox="0 0 81 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.406127 3.80432L0.406128 18.3877C5.24983 21.558 11.5736 24.2 18.9737 24.2C36.8685 24.1999 41.7122 12.5755 59.0688 12.5755C68.6217 12.5755 78.04 19.2331 81 22.1921L81 8.34842C75.8872 4.22702 68.3526 -5.63758e-05 59.0688 -5.597e-05C39.4249 -5.51113e-05 35.9266 11.5187 18.3009 11.5187C9.28626 11.6244 3.36617 6.86895 0.406127 3.80432Z"
              fill="#00C78B"
            />
            <path
              d="M5.38465 14.9007C4.98101 14.9007 4.71191 15.112 4.71191 15.429L4.71191 20.1845C5.11556 20.3959 5.5192 20.6072 5.92284 20.7129L5.92284 15.3234C6.05739 15.2177 5.78829 14.9007 5.38465 14.9007Z"
              fill="white"
            />
            <path
              d="M11.9786 20.3963C11.575 20.3963 11.3059 20.6076 11.3059 20.9246L11.3059 22.6155C11.7096 22.7211 12.1132 22.8268 12.5168 22.9325L12.5168 20.9246C12.5168 20.6076 12.2477 20.3963 11.9786 20.3963Z"
              fill="white"
            />
            <path
              d="M18.4368 17.8597C18.0331 17.8597 17.764 18.0711 17.764 18.3881L17.764 23.5663C18.1677 23.5663 18.5713 23.5663 18.975 23.5663L19.1095 23.5663L19.1095 18.3881C19.1095 18.0711 18.8404 17.8597 18.4368 17.8597Z"
              fill="white"
            />
            <path
              d="M25.5675 20.8183C25.1639 20.8183 24.8948 21.0297 24.8948 21.3467L24.8948 23.1432C25.2984 23.0375 25.7021 22.9318 26.1057 22.9318L26.1057 21.3467C26.1057 21.0297 25.8366 20.8183 25.5675 20.8183Z"
              fill="white"
            />
            <path
              d="M31.488 15.535C31.0844 15.535 30.8153 15.7463 30.8153 16.0634L30.8153 21.6642C31.2189 21.5586 31.6226 21.3472 32.0262 21.2415L32.0262 16.1691C32.1608 15.7463 31.8917 15.535 31.488 15.535Z"
              fill="white"
            />
            <path
              d="M38.0801 15.6409C37.6764 15.6409 37.4073 15.8523 37.4073 16.1693L37.4073 18.7056C37.811 18.4942 38.2146 18.2829 38.6183 18.0715L38.6183 16.275C38.7528 15.958 38.3492 15.6409 38.0801 15.6409Z"
              fill="white"
            />
            <path
              d="M44.5382 9.40538C44.1346 9.40538 43.8655 9.61673 43.8655 9.93376L43.8655 15.429C44.2691 15.2176 44.6728 15.0062 45.0764 14.9006L45.0764 9.93376C45.211 9.61673 44.9419 9.40538 44.5382 9.40538Z"
              fill="white"
            />
            <path
              d="M51.1316 10.1453C50.728 10.1453 50.4589 10.3567 50.4589 10.6737L50.4589 12.9986C50.8625 12.8929 51.2661 12.7872 51.6698 12.6816L51.6698 10.568C51.8043 10.3567 51.5352 10.1453 51.1316 10.1453Z"
              fill="white"
            />
            <path
              d="M57.725 6.12915C57.3213 6.12915 57.0522 6.34051 57.0522 6.65754L57.0522 12.0471C57.4559 12.0471 57.8595 12.0471 58.2632 12.0471L58.2632 6.76322C58.2632 6.34051 57.9941 6.12915 57.725 6.12915Z"
              fill="white"
            />
            <path
              d="M64.1829 10.1456C63.7792 10.1456 63.5101 10.3569 63.5101 10.674L63.5101 12.3648C63.9138 12.4705 64.3174 12.5761 64.7211 12.6818L64.7211 10.674C64.8556 10.3569 64.5865 10.1456 64.1829 10.1456Z"
              fill="white"
            />
            <path
              d="M71.3135 9.19455C71.3135 8.87752 71.0444 8.66616 70.6408 8.66616C70.2371 8.66616 69.968 8.87752 69.968 9.19455L69.968 14.4784C70.3717 14.6897 70.7753 14.9011 71.1789 15.0068L71.1789 9.19455L71.3135 9.19455Z"
              fill="white"
            />
            <path
              d="M76.2922 15.0065C75.8886 15.0065 75.6195 15.2178 75.6195 15.5349L75.6195 17.4371C76.0231 17.7541 76.4268 17.9654 76.8304 18.2825L76.8304 15.6405C76.965 15.2178 76.6959 15.0065 76.2922 15.0065Z"
              fill="white"
            />
          </svg>
          <div className="wrap">
           <div className="wrap-img">
              {" "} 
              <img className="img" src="/img/img.png" alt="" />
            </div>
            <div className="wrap-work">
              <div className="wrap-circle">
                <div className="circle">01</div> <h3>Вступайте в игру</h3>
              </div>

              <p>
                Присоединяйтесь к испытанию, которое создали другие
                пользователи, или придумайте свое. Подберите оптимальную
                программу с подходящими условиями. Распланируйте свое расписание
                с ориентиром на игру. Делайте ставку и начинайте играть. Цель
                игры — сбросить вес любой ценой!
              </p>

              <div className="wrap-circle">
                {" "}
                <div className="circle">02</div>
                <h3>Сбрасывайте вес</h3>{" "}
              </div>

              <p>
                Пользуясь советами и рекомендациями других участников,
                выполняйте упражнения и соблюдайте диету. Ваше здоровье —
                ценнейшая награда, а выигрыш крупной суммы денег —
                дополнительная мотивация для работы над собой. Также вы можете
                обратиться за помощью к опытным пользователям и выбрать себе
                персонального тренера.
              </p>
              <div className="wrap-circle">
                <div className="circle">03</div>
                <h3>Получайте выигрыши</h3>
              </div>

              <p>
                По окончании очередного тура вы можете получить денежное
                вознаграждение. Все зависит только от вас! Делайте фото до
                начала игры, во время состязания и по окончании, чтобы сравнить
                результаты. Отправляйте фотографии модератору, который оценит
                прогресс и выберет победителя.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkScreen;
