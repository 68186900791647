import React, { Component } from "react";
import PrivacyScreen from "screens/PrivacyScreen";
import Footer from "screens/Footer";


class Privacy extends Component {
  render() {
    return (
      <>
      <div className="privacy-page">
        <PrivacyScreen />
     
        <Footer />
        </div>
      </>
    );
  }
}

export default Privacy;