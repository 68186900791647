import React, { Component } from "react";
import { observable, action, makeObservable } from "mobx";
import { observer } from "mobx-react";

@observer
class NavMenu extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    makeObservable(this);
  }
  @observable
  isOpenMenu: boolean = false;
  @action
  toggleMenuMode = () => {
    this.isOpenMenu = true
  };
  render() {
    return (
        <nav>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <a className="nav-link scroll" href="#workScreen">
                Как это работает
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scroll" href="#motivationScreen">
                Мотивация
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scroll" href="#historyScreen">
                История успеха
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scroll" href="#countScreen">
                Счетчик
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scroll" href="#contactScreen">
                Контакты
              </a>
            </li>
          </ul>
        </nav>             
    );
  }
}

export default NavMenu;