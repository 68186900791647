import React, { Component } from "react";
import { observable, action, makeObservable } from "mobx";
import { observer } from "mobx-react";

@observer
class Logo extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    makeObservable(this);
  }
  @observable
  isOpenMenu: boolean = false;
  @action
  toggleMenuMode = () => {
    this.isOpenMenu = true;
  };
  render() {
    return (
      <div className="flex-logo">
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M39.1 -0.0996094C45.1 -0.0996094 48.1 -0.0996094 51.4 0.900391C54.9 2.20039 57.7 5.00039 59 8.50039C60 11.7004 60 14.8004 60 20.8004V39.0004C60 45.0004 60 48.0004 59 51.3004C57.7 54.8004 54.9 57.6004 51.4 58.9004C48.2 59.9004 45.1 59.9004 39.1 59.9004H20.9C14.9 59.9004 11.9 59.9004 8.6 58.9004C5.1 57.6004 2.3 54.8004 1 51.3004C0 48.0004 0 45.0004 0 39.0004V20.8004C0 14.8004 0 11.8004 1 8.50039C2.3 5.00039 5.1 2.20039 8.6 0.900391C11.8 -0.0996094 14.9 -0.0996094 20.9 -0.0996094H39.1Z"
              fill="#00C78B"
            />
            <g clip-path="url(#clip1)">
              <path
                d="M19.9208 14.558V22.0288C23.9363 4.3894 45.1867 15.0872 52.9999 7.01457V-3.53796C46.1205 6.73441 23.926 0.861518 19.9208 14.558Z"
                fill="white"
              />
              <path
                d="M5 50.5733V66.4695C17.4514 45.3437 47.9157 55.5434 47.9157 39.0973L47.6978 32.2698C42.2296 48.2075 17.9494 34.4488 5 50.5733Z"
                fill="white"
              />
              <path
                d="M19.9208 21.3133V14.5585C24.2476 29.5105 47.9363 19.6739 47.9363 34.657L47.7288 41.3185C44.865 24.9969 19.9208 34.8542 19.9208 21.3133Z"
                fill="white"
              />
              <path
                d="M48.4658 7.43982C48.4658 7.37378 48.4395 7.31044 48.3928 7.26373C48.3461 7.21703 48.2828 7.1908 48.2167 7.1908C48.1507 7.1908 48.0874 7.21703 48.0406 7.26373C47.9939 7.31044 47.9677 7.37378 47.9677 7.43982V10.3555H48.4658V7.43982Z"
                fill="#00C78B"
              />
              <path
                d="M51.0183 3.7251C51.0183 3.65906 50.992 3.59571 50.9453 3.54901C50.8986 3.50231 50.8353 3.47607 50.7692 3.47607C50.7032 3.47607 50.6398 3.50231 50.5931 3.54901C50.5464 3.59571 50.5202 3.65906 50.5202 3.7251V9.13107H51.0183V3.7251Z"
                fill="#00C78B"
              />
              <path
                d="M43.3917 8.47742C43.3917 8.41137 43.3655 8.34803 43.3188 8.30133C43.2721 8.25463 43.2087 8.22839 43.1427 8.22839C43.0767 8.22839 43.0133 8.25463 42.9666 8.30133C42.9199 8.34803 42.8937 8.41137 42.8937 8.47742V11.2894H43.3917V8.47742Z"
                fill="#00C78B"
              />
              <path
                d="M45.9133 5.0951C45.9133 5.02905 45.887 4.96571 45.8403 4.91901C45.7936 4.87231 45.7303 4.84607 45.6642 4.84607C45.5982 4.84607 45.5349 4.87231 45.4882 4.91901C45.4415 4.96571 45.4152 5.02905 45.4152 5.0951V10.5944H45.9133V5.0951Z"
                fill="#00C78B"
              />
              <path
                d="M38.2036 9.03785C38.2036 8.9718 38.1774 8.90846 38.1307 8.86176C38.084 8.81506 38.0206 8.78882 37.9546 8.78882C37.8885 8.78882 37.8252 8.81506 37.7785 8.86176C37.7318 8.90846 37.7056 8.9718 37.7056 9.03785V11.2998H38.2036V9.03785Z"
                fill="#00C78B"
              />
              <path
                d="M40.7977 6.34046C40.7977 6.27441 40.7714 6.21107 40.7247 6.16437C40.678 6.11767 40.6147 6.09143 40.5486 6.09143C40.4826 6.09143 40.4193 6.11767 40.3726 6.16437C40.3259 6.21107 40.2996 6.27441 40.2996 6.34046V11.072H40.7977V6.34046Z"
                fill="#00C78B"
              />
              <path
                d="M33.1402 9.95081C33.1402 9.88476 33.114 9.82142 33.0673 9.77472C33.0206 9.72802 32.9572 9.70178 32.8912 9.70178C32.8251 9.70178 32.7618 9.72802 32.7151 9.77472C32.6684 9.82142 32.6422 9.88476 32.6422 9.95081V11.528H33.1402V9.95081Z"
                fill="#00C78B"
              />
              <path
                d="M35.6927 7.68909C35.6927 7.62304 35.6665 7.5597 35.6198 7.513C35.5731 7.4663 35.5097 7.44006 35.4437 7.44006C35.3776 7.44006 35.3143 7.4663 35.2676 7.513C35.2209 7.5597 35.1946 7.62304 35.1946 7.68909V11.2066H35.6927V7.68909Z"
                fill="#00C78B"
              />
              <path
                d="M28.0352 10.9884C28.0352 10.9224 28.009 10.859 27.9623 10.8123C27.9156 10.7656 27.8522 10.7394 27.7862 10.7394C27.7202 10.7394 27.6568 10.7656 27.6101 10.8123C27.5634 10.859 27.5372 10.9224 27.5372 10.9884V12.5656H28.0352V10.9884Z"
                fill="#00C78B"
              />
              <path
                d="M23.0027 13.7587C23.0027 13.6926 22.9765 13.6293 22.9298 13.5826C22.8831 13.5359 22.8197 13.5096 22.7537 13.5096C22.6876 13.5096 22.6243 13.5359 22.5776 13.5826C22.5309 13.6293 22.5046 13.6926 22.5046 13.7587V16.8093H23.0027V13.7587Z"
                fill="#00C78B"
              />
              <path
                d="M30.5877 8.33252C30.5877 8.26648 30.5615 8.20314 30.5148 8.15643C30.4681 8.10973 30.4047 8.0835 30.3387 8.0835C30.2726 8.0835 30.2093 8.10973 30.1626 8.15643C30.1159 8.20314 30.0897 8.26648 30.0897 8.33252V11.85H30.5877V8.33252Z"
                fill="#00C78B"
              />
              <path
                d="M25.4822 10.4908C25.4822 10.4248 25.456 10.3615 25.4093 10.3148C25.3626 10.2681 25.2993 10.2418 25.2332 10.2418C25.1672 10.2418 25.1038 10.2681 25.0571 10.3148C25.0104 10.3615 24.9842 10.4248 24.9842 10.4908V14.0084H25.4822V10.4908Z"
                fill="#00C78B"
              />
              <path
                d="M24.0611 25.8473C24.0611 25.7812 24.0873 25.7179 24.134 25.6712C24.1807 25.6245 24.2441 25.5983 24.3101 25.5983C24.3762 25.5983 24.4395 25.6245 24.4862 25.6712C24.5329 25.7179 24.5592 25.7812 24.5592 25.8473V28.763H24.0611V25.8473Z"
                fill="#00C78B"
              />
              <path
                d="M21.6641 21.9766C21.6641 21.9105 21.6904 21.8472 21.7371 21.8005C21.7838 21.7538 21.8471 21.7275 21.9132 21.7275C21.9792 21.7275 22.0425 21.7538 22.0892 21.8005C22.1359 21.8472 22.1622 21.9105 22.1622 21.9766V26.9986H21.6641V21.9766Z"
                fill="#00C78B"
              />
              <path
                d="M29.1661 27.7361C29.1661 27.67 29.1923 27.6067 29.239 27.56C29.2857 27.5133 29.3491 27.4871 29.4151 27.4871C29.4811 27.4871 29.5445 27.5133 29.5912 27.56C29.6379 27.6067 29.6641 27.67 29.6641 27.7361V30.548H29.1661V27.7361Z"
                fill="#00C78B"
              />
              <path
                d="M26.6136 25.1107C26.6136 25.0447 26.6398 24.9813 26.6865 24.9346C26.7332 24.8879 26.7966 24.8617 26.8626 24.8617C26.9287 24.8617 26.992 24.8879 27.0387 24.9346C27.0854 24.9813 27.1116 25.0447 27.1116 25.1107V30.6101H26.6136V25.1107Z"
                fill="#00C78B"
              />
              <path
                d="M34.2817 28.9703C34.2817 28.9043 34.3079 28.841 34.3546 28.7943C34.4013 28.7475 34.4647 28.7213 34.5307 28.7213C34.5968 28.7213 34.6601 28.7475 34.7068 28.7943C34.7535 28.841 34.7797 28.9043 34.7797 28.9703V30.8899H34.2817V28.9703Z"
                fill="#00C78B"
              />
              <path
                d="M31.7287 26.3449C31.7287 26.2788 31.7549 26.2155 31.8016 26.1688C31.8483 26.1221 31.9117 26.0958 31.9777 26.0958C32.0438 26.0958 32.1071 26.1221 32.1538 26.1688C32.2005 26.2155 32.2268 26.2788 32.2268 26.3449V31.0764H31.7287V26.3449Z"
                fill="#00C78B"
              />
              <path
                d="M39.3867 30.3505C39.3867 30.2844 39.4129 30.2211 39.4596 30.1744C39.5063 30.1277 39.5696 30.1014 39.6357 30.1014C39.7017 30.1014 39.7651 30.1277 39.8118 30.1744C39.8585 30.2211 39.8847 30.2844 39.8847 30.3505V32.2804H39.3867V30.3505Z"
                fill="#00C78B"
              />
              <path
                d="M36.8342 27.9955C36.8342 27.9294 36.8604 27.8661 36.9071 27.8194C36.9538 27.7727 37.0171 27.7465 37.0832 27.7465C37.1492 27.7465 37.2126 27.7727 37.2593 27.8194C37.306 27.8661 37.3322 27.9294 37.3322 27.9955V31.513H36.8342V27.9955Z"
                fill="#00C78B"
              />
              <path
                d="M44.4916 33.0691C44.4916 33.003 44.5179 32.9397 44.5646 32.893C44.6113 32.8463 44.6746 32.8201 44.7407 32.8201C44.8067 32.8201 44.8701 32.8463 44.9168 32.893C44.9635 32.9397 44.9897 33.003 44.9897 33.0691V35.5594H44.4916V33.0691Z"
                fill="#00C78B"
              />
              <path
                d="M41.9391 30.0494C41.9391 29.9834 41.9654 29.9201 42.0121 29.8734C42.0588 29.8267 42.1221 29.8004 42.1882 29.8004C42.2542 29.8004 42.3176 29.8267 42.3643 29.8734C42.411 29.9201 42.4372 29.9834 42.4372 30.0494V33.5669H41.9391V30.0494Z"
                fill="#00C78B"
              />
              <path
                d="M44.5018 43.9124C44.5018 43.8797 44.4953 43.8473 44.4828 43.8171C44.4703 43.7868 44.452 43.7594 44.4288 43.7363C44.4057 43.7131 44.3783 43.6948 44.348 43.6823C44.3178 43.6698 44.2855 43.6633 44.2528 43.6633C44.22 43.6633 44.1877 43.6698 44.1575 43.6823C44.1272 43.6948 44.0998 43.7131 44.0767 43.7363C44.0535 43.7594 44.0352 43.7868 44.0227 43.8171C44.0102 43.8473 44.0037 43.8797 44.0037 43.9124V46.8281H44.5018V43.9124Z"
                fill="#00C78B"
              />
              <path
                d="M47.0543 39.4093C47.0543 39.3433 47.028 39.2799 46.9813 39.2332C46.9346 39.1865 46.8713 39.1603 46.8052 39.1603C46.7392 39.1603 46.6759 39.1865 46.6292 39.2332C46.5824 39.2799 46.5562 39.3433 46.5562 39.4093V44.8153H47.0543V39.4093Z"
                fill="#00C78B"
              />
              <path
                d="M39.3968 46.2672C39.3968 46.2345 39.3904 46.2021 39.3778 46.1719C39.3653 46.1417 39.347 46.1143 39.3239 46.0911C39.3007 46.068 39.2733 46.0497 39.2431 46.0371C39.2129 46.0246 39.1805 46.0182 39.1478 46.0182C39.1151 46.0182 39.0827 46.0246 39.0525 46.0371C39.0223 46.0497 38.9948 46.068 38.9717 46.0911C38.9486 46.1143 38.9302 46.1417 38.9177 46.1719C38.9052 46.2021 38.8987 46.2345 38.8987 46.2672V49.0791H39.3968V46.2672Z"
                fill="#00C78B"
              />
              <path
                d="M41.9493 42.7186C41.9493 42.6526 41.9231 42.5892 41.8763 42.5425C41.8296 42.4958 41.7663 42.4696 41.7003 42.4696C41.6342 42.4696 41.5709 42.4958 41.5242 42.5425C41.4775 42.5892 41.4512 42.6526 41.4512 42.7186V48.218H41.9493V42.7186Z"
                fill="#00C78B"
              />
              <path
                d="M34.2817 48.1044C34.2817 48.0383 34.2554 47.975 34.2087 47.9283C34.162 47.8816 34.0987 47.8553 34.0327 47.8553C33.9666 47.8553 33.9033 47.8816 33.8566 47.9283C33.8099 47.975 33.7836 48.0383 33.7836 48.1044V50.5635H34.2817V48.1044Z"
                fill="#00C78B"
              />
              <path
                d="M36.8342 44.3582C36.8342 44.2921 36.8079 44.2288 36.7612 44.1821C36.7145 44.1354 36.6512 44.1091 36.5851 44.1091C36.5191 44.1091 36.4558 44.1354 36.4091 44.1821C36.3624 44.2288 36.3361 44.2921 36.3361 44.3582V49.8368H36.8342V44.3582Z"
                fill="#00C78B"
              />
              <path
                d="M29.1762 48.986C29.1762 48.9199 29.15 48.8566 29.1033 48.8099C29.0566 48.7632 28.9932 48.7369 28.9272 48.7369C28.8611 48.7369 28.7978 48.7632 28.7511 48.8099C28.7044 48.8566 28.6782 48.9199 28.6782 48.986V51.7564H29.1762V48.986Z"
                fill="#00C78B"
              />
              <path
                d="M31.7287 45.4586C31.7287 45.3926 31.7025 45.3292 31.6558 45.2825C31.6091 45.2358 31.5457 45.2096 31.4797 45.2096C31.4136 45.2096 31.3503 45.2358 31.3036 45.2825C31.2569 45.3292 31.2307 45.3926 31.2307 45.4586V51.0929H31.7287V45.4586Z"
                fill="#00C78B"
              />
              <path
                d="M24.0712 49.9202C24.0712 49.8541 24.045 49.7908 23.9983 49.7441C23.9516 49.6974 23.8883 49.6711 23.8222 49.6711C23.7562 49.6711 23.6928 49.6974 23.6461 49.7441C23.5994 49.7908 23.5732 49.8541 23.5732 49.9202V53.033H24.0712V49.9202Z"
                fill="#00C78B"
              />
              <path
                d="M19.0387 51.3936C19.0387 51.3275 19.0125 51.2642 18.9658 51.2175C18.9191 51.1708 18.8557 51.1445 18.7897 51.1445C18.7236 51.1445 18.6603 51.1708 18.6136 51.2175C18.5669 51.2642 18.5406 51.3275 18.5406 51.3936V54.9007H19.0387V51.3936Z"
                fill="#00C78B"
              />
              <path
                d="M26.6237 45.8943C26.6237 45.8282 26.5975 45.7649 26.5508 45.7182C26.5041 45.6715 26.4407 45.6453 26.3747 45.6453C26.3087 45.6453 26.2453 45.6715 26.1986 45.7182C26.1519 45.7649 26.1257 45.8282 26.1257 45.8943V52.3586H26.6237V45.8943Z"
                fill="#00C78B"
              />
              <path
                d="M21.5187 46.5682C21.5187 46.5022 21.4925 46.4389 21.4458 46.3922C21.3991 46.3454 21.3358 46.3192 21.2697 46.3192C21.2037 46.3192 21.1403 46.3454 21.0936 46.3922C21.0469 46.4389 21.0207 46.5022 21.0207 46.5682V54.0079H21.5187V46.5682Z"
                fill="#00C78B"
              />
              <path
                d="M16.3206 48.986C16.3206 48.9199 16.2943 48.8566 16.2476 48.8099C16.2009 48.7632 16.1376 48.7369 16.0715 48.7369C16.0055 48.7369 15.9421 48.7632 15.8954 48.8099C15.8487 48.8566 15.8225 48.9199 15.8225 48.986V57.2869H16.3206V48.986Z"
                fill="#00C78B"
              />
              <path
                d="M13.7473 53.5305C13.7473 53.4645 13.721 53.4011 13.6743 53.3544C13.6276 53.3077 13.5643 53.2815 13.4982 53.2815C13.4322 53.2815 13.3688 53.3077 13.3221 53.3544C13.2754 53.4011 13.2492 53.4645 13.2492 53.5305V59.5591H13.7473V53.5305Z"
                fill="#00C78B"
              />
              <path
                d="M8.37236 56.6433C8.37982 56.6041 8.37881 56.5638 8.36942 56.525C8.36003 56.4863 8.34247 56.4499 8.31791 56.4185C8.29335 56.3871 8.26236 56.3612 8.22702 56.3428C8.19167 56.3243 8.15279 56.3135 8.11296 56.3113C8.04045 56.3192 7.97387 56.355 7.92736 56.4112C7.88086 56.4674 7.85811 56.5395 7.86393 56.6122V62.6615H8.36199L8.37236 56.6433Z"
                fill="#00C78B"
              />
              <path
                d="M11.0286 51.5385C11.0286 51.4724 11.0024 51.4091 10.9557 51.3624C10.909 51.3157 10.8457 51.2894 10.7796 51.2894C10.7136 51.2894 10.6502 51.3157 10.6035 51.3624C10.5568 51.4091 10.5306 51.4724 10.5306 51.5385V60.0365H11.0286V51.5385Z"
                fill="#00C78B"
              />
              <path
                d="M24.0819 20.6281C23.9055 20.5554 19.672 18.667 19.672 11.0717H20.1701C20.1701 18.3349 24.2375 20.1611 24.3205 20.1715L24.0819 20.6281Z"
                fill="#00C78B"
              />
              <path
                d="M43.7654 37.8418L43.5371 37.406C43.5371 37.406 47.3659 35.3308 47.3659 28.3788H47.864C47.8536 35.6213 43.9314 37.7588 43.7654 37.8418Z"
                fill="#00C78B"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="60" height="60" fill="white" />
            </clipPath>
            <clipPath id="clip1">
              <rect
                width="48"
                height="70.0078"
                fill="white"
                transform="translate(5 -3.53809)"
              />
            </clipPath>
          </defs>
        </svg>

        <div className="logo">Slim Game</div>
      </div>
    );
  }
}

export default Logo;
