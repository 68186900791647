import { action, observable, makeObservable } from "mobx";
import React, { Component } from "react";
import { observer } from "mobx-react";

const API_URL = "https://slim-game.dev.fox-dev.ru/";
@observer
class ContactScreen extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    makeObservable(this);
  }
  @observable
  text: string = "";
  @observable
  name: string = "";
  @observable
  email: string = "";
  @observable
  phone: string | undefined = undefined;
  @observable
  message: string = "";
  @observable
  messageErorr = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  @action
  setName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.name = event.target.value;
  };
  @action
  setEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.email = event.target.value;
  };
  @action
  setPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.phone = event.target.value;
    if (this.phone === "") {
      this.phone = undefined;
    }
  };
  @action
  setMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.message = event.target.value;
    console.log(this.name);
  };
  @observable
  isOpenMenu: boolean = false;
  @action
  toggleMenuMode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let response = await fetch(`${API_URL}api/v1/send/message_email/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fio: this.name,
          phone: this.phone,
          email: this.email,
          message: this.message,
        }),
      });
      console.log(response.status);
      if (response.status === 200) {
        console.log(response);
        this.text = "Ваша заявка принята";
      } else if (response.status === 400) {
        this.text = "Произошла ошибка, пожалуйста, попробуйте позже";
      } else {
        this.text = "Произошла ошибка, пожалуйста, попробуйте позже";
      }
      this.isOpenMenu = true;
    } catch (e) {
      this.text = "Произошла ошибка, пожалуйста, попробуйте позже";
      this.isOpenMenu = true;
      console.log(e);
      return false;
    }
  };
  @action
  closeMenuMode = () => {
    this.isOpenMenu = false;
  };

  render() {
    return (
      <>
        <div className="contactScreen" id="contactScreen">
          <div className="container">
            <h2>Контакты</h2>
            <svg
              className="metr"
              width="81"
              height="25"
              viewBox="0 0 81 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.406127 3.80432L0.406128 18.3877C5.24983 21.558 11.5736 24.2 18.9737 24.2C36.8685 24.1999 41.7122 12.5755 59.0688 12.5755C68.6217 12.5755 78.04 19.2331 81 22.1921L81 8.34842C75.8872 4.22702 68.3526 -5.63758e-05 59.0688 -5.597e-05C39.4249 -5.51113e-05 35.9266 11.5187 18.3009 11.5187C9.28626 11.6244 3.36617 6.86895 0.406127 3.80432Z"
                fill="#00C78B"
              />
              <path
                d="M5.38465 14.9007C4.98101 14.9007 4.71191 15.112 4.71191 15.429L4.71191 20.1845C5.11556 20.3959 5.5192 20.6072 5.92284 20.7129L5.92284 15.3234C6.05739 15.2177 5.78829 14.9007 5.38465 14.9007Z"
                fill="white"
              />
              <path
                d="M11.9786 20.3963C11.575 20.3963 11.3059 20.6076 11.3059 20.9246L11.3059 22.6155C11.7096 22.7211 12.1132 22.8268 12.5168 22.9325L12.5168 20.9246C12.5168 20.6076 12.2477 20.3963 11.9786 20.3963Z"
                fill="white"
              />
              <path
                d="M18.4368 17.8597C18.0331 17.8597 17.764 18.0711 17.764 18.3881L17.764 23.5663C18.1677 23.5663 18.5713 23.5663 18.975 23.5663L19.1095 23.5663L19.1095 18.3881C19.1095 18.0711 18.8404 17.8597 18.4368 17.8597Z"
                fill="white"
              />
              <path
                d="M25.5675 20.8183C25.1639 20.8183 24.8948 21.0297 24.8948 21.3467L24.8948 23.1432C25.2984 23.0375 25.7021 22.9318 26.1057 22.9318L26.1057 21.3467C26.1057 21.0297 25.8366 20.8183 25.5675 20.8183Z"
                fill="white"
              />
              <path
                d="M31.488 15.535C31.0844 15.535 30.8153 15.7463 30.8153 16.0634L30.8153 21.6642C31.2189 21.5586 31.6226 21.3472 32.0262 21.2415L32.0262 16.1691C32.1608 15.7463 31.8917 15.535 31.488 15.535Z"
                fill="white"
              />
              <path
                d="M38.0801 15.6409C37.6764 15.6409 37.4073 15.8523 37.4073 16.1693L37.4073 18.7056C37.811 18.4942 38.2146 18.2829 38.6183 18.0715L38.6183 16.275C38.7528 15.958 38.3492 15.6409 38.0801 15.6409Z"
                fill="white"
              />
              <path
                d="M44.5382 9.40538C44.1346 9.40538 43.8655 9.61673 43.8655 9.93376L43.8655 15.429C44.2691 15.2176 44.6728 15.0062 45.0764 14.9006L45.0764 9.93376C45.211 9.61673 44.9419 9.40538 44.5382 9.40538Z"
                fill="white"
              />
              <path
                d="M51.1316 10.1453C50.728 10.1453 50.4589 10.3567 50.4589 10.6737L50.4589 12.9986C50.8625 12.8929 51.2661 12.7872 51.6698 12.6816L51.6698 10.568C51.8043 10.3567 51.5352 10.1453 51.1316 10.1453Z"
                fill="white"
              />
              <path
                d="M57.725 6.12915C57.3213 6.12915 57.0522 6.34051 57.0522 6.65754L57.0522 12.0471C57.4559 12.0471 57.8595 12.0471 58.2632 12.0471L58.2632 6.76322C58.2632 6.34051 57.9941 6.12915 57.725 6.12915Z"
                fill="white"
              />
              <path
                d="M64.1829 10.1456C63.7792 10.1456 63.5101 10.3569 63.5101 10.674L63.5101 12.3648C63.9138 12.4705 64.3174 12.5761 64.7211 12.6818L64.7211 10.674C64.8556 10.3569 64.5865 10.1456 64.1829 10.1456Z"
                fill="white"
              />
              <path
                d="M71.3135 9.19455C71.3135 8.87752 71.0444 8.66616 70.6408 8.66616C70.2371 8.66616 69.968 8.87752 69.968 9.19455L69.968 14.4784C70.3717 14.6897 70.7753 14.9011 71.1789 15.0068L71.1789 9.19455L71.3135 9.19455Z"
                fill="white"
              />
              <path
                d="M76.2922 15.0065C75.8886 15.0065 75.6195 15.2178 75.6195 15.5349L75.6195 17.4371C76.0231 17.7541 76.4268 17.9654 76.8304 18.2825L76.8304 15.6405C76.965 15.2178 76.6959 15.0065 76.2922 15.0065Z"
                fill="white"
              />
            </svg>
            <div className="wrap-contact">
              <div className="wrap-form">
                <h3>Напишите нам</h3>
                <form
                  className="contact-form"
                  onSubmit={(e) => this.toggleMenuMode(e)}
                >
                  <div>
                    {" "}
                    <input
                      required
                      maxLength={30}
                      type="text"
                      name="name"
                      className="control-input"
                      id="name"
                      value={this.name}
                      placeholder="Ваше имя*"
                      onChange={(event) => this.setName(event)}
                    />
                  </div>
                  <div>
                    {" "}
                    <input
                      required
                      maxLength={30}
                      type="email"
                      pattern="^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$"
                      name="email"
                      className="control-input"
                      id="email"
                      placeholder="E-mail*"
                      value={this.email}
                      onChange={(event) => this.setEmail(event)}
                    />
                  </div>
                  <div>
                    {" "}
                    <input
                      type="tel"
                      maxLength={15}
                      name="phone"
                      pattern="\+?[0-9\s\-\(\)]+"
                      className="control-input"
                      id="phone"
                      placeholder="Номер телефона"
                      value={this.phone}
                      onChange={(event) => this.setPhone(event)}
                    />
                  </div>
                  <div>
                    {" "}
                    <textarea
                      required
                      maxLength={1000}
                      name="message"
                      className="control-input"
                      id="message"
                      placeholder="Сообщение*"
                      value={this.message}
                      onChange={(event) => this.setMessage(event)}
                    />
                  </div>
                  <div className="button-wrap-history">
                    <button type="submit" className="button-history mt-26">
                      Отправить
                    </button>
                  </div>
                </form>
              </div>

              <div className="card-contact">
                <h3>Свяжитесь с нами</h3>
                {/* <div className="contact-item">
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00005 0C3.32412 0 0.333374 2.99074 0.333374 6.66668C0.333374 7.7702 0.609272 8.86434 1.13376 9.8348L6.63548 19.7852C6.70873 19.9178 6.8483 20 7.00005 20C7.15181 20 7.29138 19.9178 7.36462 19.7852L12.8684 9.83152C13.3908 8.86434 13.6667 7.77016 13.6667 6.66664C13.6667 2.99074 10.676 0 7.00005 0ZM7.00005 10C5.16208 10 3.66673 8.50465 3.66673 6.66668C3.66673 4.82871 5.16208 3.33336 7.00005 3.33336C8.83802 3.33336 10.3334 4.82871 10.3334 6.66668C10.3334 8.50465 8.83802 10 7.00005 10Z"
                      fill="#EC615B"
                    />
                  </svg>
                  <div>
                    <div>ул. Верности, д. 28, корп. 1, кв 46 </div>
                    <div>Россия, г. Санкт-Петербург,</div>195256
                  </div>
                </div> */}
                {/* <div className="contact-item">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4557 14.678L16.6646 11.8869C15.6678 10.8901 13.9732 11.2889 13.5745 12.5847C13.2754 13.4819 12.2786 13.9803 11.3815 13.7809C9.3879 13.2825 6.69652 10.6908 6.19812 8.59746C5.89907 7.7003 6.49716 6.70349 7.39429 6.40448C8.69013 6.00576 9.08886 4.31119 8.09205 3.31438L5.30099 0.523324C4.50354 -0.174441 3.30738 -0.174441 2.60961 0.523324L0.715678 2.41726C-1.17826 4.41087 0.915039 9.69395 5.60003 14.3789C10.285 19.0639 15.5681 21.2569 17.5617 19.2633L19.4557 17.3694C20.1535 16.5719 20.1535 15.3757 19.4557 14.678Z"
                      fill="#EC615B"
                    />
                  </svg>
                  <div>
                    <div>0 800 153 15 13</div> <div>0 800 153 15 14</div> 0 800
                    153 15 15
                  </div>
                </div> */}
                <div className="contact-item">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M13.2576 10.1024L20.0001 14.3649V5.65991L13.2576 10.1024Z"
                        fill="#EC615B"
                      />
                      <path
                        d="M0 5.65991V14.3649L6.7425 10.1024L0 5.65991Z"
                        fill="#EC615B"
                      />
                      <path
                        d="M18.7501 3.125H1.2501C0.626348 3.125 0.131348 3.59 0.0375977 4.18875L10.0001 10.7525L19.9626 4.18875C19.8688 3.59 19.3738 3.125 18.7501 3.125Z"
                        fill="#EC615B"
                      />
                      <path
                        d="M12.1124 10.8577L10.3437 12.0227C10.2387 12.0914 10.1199 12.1252 9.99992 12.1252C9.87992 12.1252 9.76117 12.0914 9.65617 12.0227L7.88742 10.8564L0.039917 15.8202C0.136167 16.4139 0.628667 16.8752 1.24992 16.8752H18.7499C19.3712 16.8752 19.8637 16.4139 19.9599 15.8202L12.1124 10.8577Z"
                        fill="#EC615B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div>mail@slimgame.ru</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => this.closeMenuMode()}
          className={`modal ${this.isOpenMenu ? "active-form" : ""}`}
        >
          <div className="modal-dialog-form">
            <p>{this.text}</p>
            <div className="button-wrap-history">
              <button
                onClick={() => this.closeMenuMode()}
                className="button-history mt-26"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactScreen;
