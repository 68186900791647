import React, { Component } from "react";
import UserDeleteScreen from "screens/UserDeleteScreen";
import Footer from "screens/Footer";

class Delete extends Component {
  render() {
    return (
      <>
        <div className="privacy-page">
          <UserDeleteScreen />
          <Footer />
        </div>
      </>
    );
  }
}

export default Delete;
