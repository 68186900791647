import React, { Component } from "react";

class AppButton extends Component {
  render() {
    return (
      <div className="wrap-button">
        <a
          rel="noreferrer"
          className="btn-form"
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.slimgame.mobile"
        >
          <img className="img-button" src="/img/google.png" alt="" />
        </a>
        {/* <a
          rel="noreferrer"
          className="btn-form"
          target="_blank"
          href="https://www.slimgame.ru/"
        >
          <img className="img-button-apple" src="/img/apple.png" alt="" />
        </a> */}
      </div>
    );
  }
}

export default AppButton;
